.PersonelProfile
{
    min-width: 8rem;
    min-height: 8rem;
    background: url("../../../../assets/Images/ProfileImage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    margin-top:3rem;
}

@media (max-width: 1000px)
{
    .PersonelProfile
    {
        margin-top: 2rem;
        min-width: 6rem;
        min-height: 6rem;
    }
}