@font-face 
{
    font-family: 'Parisienne';
    src: url('../../assets/fonts/Parisienne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.HomePageDiv
{
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../assets/Images/HomePageBackground.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;   
    display: flex;
    align-items: center;
    justify-content: center;
}

.CenterDiv
{
    margin-top: 10%;
    width: 60%;
    height: 20%;
    background-color: transparent;
    z-index: 10;
}

.PersonelName
{
    color: white;
    font-family: "Parisienne";
    font-size: 3rem;
}

.PersonelInfo
{
    color: white;
    font-size: 2rem;
}

.Job
{
    color: gold;
}

@media(max-width:500px)
{
    .CenterDiv
    {
        margin-top: -10%;
        width: 80%;
    }
    .PersonelName
    {
        font-size: 2.5rem;
    }

}