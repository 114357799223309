.OuterDiv
{
    width: 100%;
    height: 100%;
    display: flex;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0,0,0,0.3)),url("../../assets/Images/ProjectsPageBackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}

.InnerDiv
{
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.PageHeader
{
    font-size: 2.5rem;
    font-weight: bold;  
    color: gold;
}

.OuterDiv::-webkit-scrollbar {
    width: 0;
}

.OuterDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}


@media(max-width: 1000px)
{
    .PageHeader
    {
        font-size: 2rem;
    }
    
    .InnerDiv
    {
        display: block;
    }
}