.FactDivContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.FactDiv
{
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    border: 7px solid green;
}

.SoftwareEngineeringWallpaper
{
    background: url("../../../assets/Images/SoftwareEngineeringWallpeper.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.WebDevelopmentWallpaper
{
    background: url("../../../assets/Images/WebDevelopmentWallpaper.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.MobileDevelopmentWallpaper
{
    background: url("../../../assets/Images/MobileDevelopmentWallpaper.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.TeamPlayerWallpaper
{
    background: url("../../../assets/Images/TeamPlayerWallpaper.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.FactName
{
    color: white;
    font-size: 1.3rem;
    margin-top: 1rem;
    font-weight: bold;
    width: auto;
}

@media(max-width: 1200px)
{
    .FactDivContainer
    {
        display: flex;
        flex-direction: row;
        margin: 2rem;
        margin-left: auto;
        float: left;
    }
    .FactDiv
    {
        width: 5rem;
        height: 5rem;
    }

    .FactName
    {
        font-size: 1.3rem;
        margin: 1rem 0;
        margin-top: 2rem;
    }
}